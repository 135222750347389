export function searchedProductsBySKU(state = [], action) {
  switch (action.type) {
    case 'SEARCHED_PRODUCTS_BY_SKU':
      return action.payload;

    default:
      return state;
  }
}
export function products(state = { items: [] }, action) {
  switch (action.type) {
    case 'SET_PRODUCTS':
      return action.payload;

    default:
      return state;
  }
}
export function product(state = null, action) {
  switch (action.type) {
    case 'SET_PRODUCT':
      return action.payload;

    default:
      return state;
  }
}
export function brands(state = [], action) {
  switch (action.type) {
    case 'SET_BRANDS':
      return action.payload;

    default:
      return state;
  }
}
export function categories(state = [], action) {
  switch (action.type) {
    case 'SET_CATEGORIES':
      return action.payload;

    default:
      return state;
  }
}

export function sizes(state = [], action) {
  switch (action.type) {
    case 'SET_SIZES':
      return action.payload;

    default:
      return state;
  }
}
export function subcategories(state = [], action) {
  switch (action.type) {
    case 'SET_SUBCATEGORIES':
      return action.payload;

    default:
      return state;
  }
}

export function vouchers(state = [], action) {
  switch (action.type) {
    case 'SET_VOUCHERS':
      return action.payload;

    default:
      return state;
  }
}

export function genders(state = [], action) {
  switch (action.type) {
    case 'SET_GENDERS':
      return action.payload;

    default:
      return state;
  }
}

export function seasons(state = [], action) {
  switch (action.type) {
    case 'SET_SEASONS':
      return action.payload;

    default:
      return state;
  }
}
