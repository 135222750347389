import { useCallback, useEffect, useState } from 'react';
import useTranslation from '../../hooks/useTranslation';
import useResponsive from '../../hooks/useResponsive';
import { useSelector } from 'react-redux';
import { useRouterWithLang } from 'hooks/useRouterWithLang';

const Chat = () => {
  const [maximized, setMaximized] = useState(false);
  const { locale } = useTranslation();
  const { isMobile } = useResponsive();
  const router = useRouterWithLang();
  const PERMISSIONS = useSelector((state) => state.permissions);

  const runChat = useCallback(async (d, a) => {
    window.kayako = a;
    window.kayako.config = {
      hideLauncher: true,
      styles: {},
    };
    a.readyQueue = [];
    a.newEmbedCode = !0;
    a.ready = function (b) {
      a.readyQueue.push(b);
    };
    a._settings = {
      apiUrl: 'https://droppy.kayako.com/api/v1',
      messengerUrl: 'https://droppy.kayakocdn.com/messenger',
      realtimeUrl: 'wss://kre.kayako.net/socket',
    };
    let b = d.createElement('script');
    b.async = !0;
    b.type = 'text/javascript';
    b.src = a._settings.messengerUrl;
    b.crossOrigin = 'anonymous';
    let c = d.getElementsByTagName('script')[0];
    c.parentNode.insertBefore(b, c);
  }, []);

  useEffect(() => {
    if (
      router.pathname.match('/shop/') ||
      router.pathname.match('/login') ||
      router.pathname.match('/cart') ||
      router.pathname.match('/checkout') ||
      router.pathname === '/' ||
      router.pathname.match('/home') ||
      !PERMISSIONS.IS_FREE_USER
    ) {
      return null;
    } else {
      if (!window.kayako) {
        runChat(document, window.kayako || {}).then(() => {});
      } else {
        if (typeof window.kayako.show === 'function') {
          window.kayako.show();
        }
      }
    }
    return () => {
      if (window.kayako && window.kayako.hide) {
        window.kayako.hide();
      }
    };
  }, [runChat, maximized, router.pathname, PERMISSIONS.IS_FREE_USER]);

  const toggleChat = useCallback((e) => {
    if (window?.kayako?.visibility() === 'minimized') {
      window.kayako.maximize();
      setMaximized(true);
    } else {
      window?.kayako?.minimize();
      setMaximized(false);
    }
    e.preventDefault();
  }, []);

  useEffect(() => {
    const kayakoIFrameContainer = document.getElementById('kayako-messenger');
    setTimeout(function () {
      if (window.kayako) {
        window.kayako.ready(function () {
          window.kayako.config = {
            forceLocale: locale,
          };
          if (maximized === false) {
            window.kayako.minimize();
          }
          if (
            router.pathname.match('/shop/') ||
            router.pathname.match('/login') ||
            router.pathname.match('/cart') ||
            router.pathname.match('/checkout') ||
            router.pathname === '/' ||
            router.pathname.match('/home') ||
            !PERMISSIONS.IS_FREE_USER
          ) {
            if (kayakoIFrameContainer?.style) {
              kayakoIFrameContainer.style.display = 'none';
              kayakoIFrameContainer.style.zIndex = '0!important';
            }
          }
          if (kayakoIFrameContainer?.style) {
            kayakoIFrameContainer.style.zIndex = '4!important';
          }
        });
      }
    });
  }, [PERMISSIONS.IS_FREE_USER, locale, maximized, router.pathname]);

  if (
    router.pathname.match('/shop/') ||
    router.pathname.match('/login') ||
    router.pathname.match('/cart') ||
    router.pathname.match('/checkout') ||
    router.pathname === '/' ||
    router.pathname.match('/home') ||
    !PERMISSIONS.IS_FREE_USER
  ) {
    return null;
  }

  return (
    <div className={`${isMobile && maximized ? 'mobile-chat' : 'chat'}`}>
      {maximized ? (
        <div className="close-chat" onClick={toggleChat}>
          {isMobile && maximized ? (
            <div className="transparent-chat" />
          ) : (
            <img src="/images/chat-kayako-close.svg" alt="kayako-close" />
          )}
        </div>
      ) : (
        <div onClick={toggleChat}>
          <img src="/images/chat-kayako.svg" alt="kayako" />
        </div>
      )}
      <style jsx>{`
        .chat {
          position: fixed;
          bottom: 20px;
          right: 25px;
          z-index: 5;
        }
        .mobile-chat {
          position: fixed;
          top: 10px;
          right: 10px;
          z-index: 5;
        }
        .chat img,
        .mobile-chat img {
          height: 45px;
          cursor: pointer;
        }
        .transparent-chat {
          background: transparent;
          height: 60px;
          width: 60px;
        }
        :global(#kayako-messenger) {
          z-index: 4 !important;
        }
      `}</style>
    </div>
  );
};

export default Chat;
