import { combineReducers } from 'redux';

import { addresses, searchedAddressesByEmail } from './address';
import { channels } from './channel';
import { countries } from './country';
import { loader } from './loader';
import {
  order,
  orderBill,
  orderIsLoading,
  orderNote,
  orderPayment,
  orderProducts,
  orderRecipient,
  orders,
  orderShipping,
  ordersIsLoading,
  orderStep,
  ordersUnpaid,
  orderTotal,
} from './order';
import { permissions } from './permissions';
import {
  brands,
  categories,
  product,
  products,
  searchedProductsBySKU,
  subcategories,
  vouchers,
  sizes,
  genders,
  seasons,
} from './product';
import { refund, refundIsLoading, refunds, refundsIsLoading } from './refund';
import { refundReasons } from './refund-reason';
import { setting } from './setting';
import {
  currency,
  description,
  locale,
  shippingCountry,
  translations,
} from './translations';
import {
  handleSidebar,
  howToStart,
  UI,
  cartProducts,
  cartProductsVetrina,
  previousRoute,
  referralCode,
  anonymousUserId,
} from './ui';
import {
  countryInfo,
  isLoggedIn,
  user,
  userIsLoading,
  showNewSignupPopup,
} from './user';

const appReducer = combineReducers({
  user,
  userIsLoading,
  isLoggedIn,
  showNewSignupPopup,
  orders,
  ordersIsLoading,
  ordersUnpaid,
  order,
  orderIsLoading,
  orderProducts,
  cartProducts,
  orderRecipient,
  description,
  orderStep,
  orderBill,
  currency,
  orderNote,
  orderShipping,
  refund,
  refundIsLoading,
  refunds,
  refundsIsLoading,
  refundReasons,
  countries,
  addresses,
  searchedAddressesByEmail,
  searchedProductsBySKU,
  shippingCountry,
  orderTotal,
  translations,
  orderPayment,
  setting,
  products,
  brands,
  categories,
  subcategories,
  sizes,
  product,
  channels,
  locale,
  permissions,
  loader,
  UI,
  howToStart,
  handleSidebar,
  countryInfo,
  cartProductsVetrina,
  previousRoute,
  vouchers,
  referralCode,
  genders,
  seasons,
  anonymousUserId,
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(
      {
        setting: state?.setting,
        countries: state?.countries,
        locale: state?.locale,
      },
      action
    );
  }
  return appReducer(state, action);
};

export default rootReducer;
